<template>
  <v-row>
    <span class="warn-title">Ongoing will remain active for one month from the creation date</span>
    <v-col
      cols="12"
      class="align-baseline d-flex"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      ref="hours"
    >
      <label class="title-label"
        >Hours*
        <tip-icon>
          Enter the number of hours the volunteer is expected to work.<br />
          This number is used by your volunteer check-in feature.
          <br />For multi-date needs, Enter the average number of hours expected
          each day.
        </tip-icon>
      </label>
      <v-text-field
        v-model="need.hours"
        label="Example: 4 or 3.5"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
        type="number"
        :min="1"
        :error-messages="hoursMessage"
        @input="onChange"
      />
    </v-col>

    <v-col
      cols="12"
      class="align-baseline d-flex"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label"
        >Hours Description*
        <tip-icon>
          Let the volunteer know when the need is scheduled to start and end.
        </tip-icon>
      </label>
      <v-text-field
        v-model="need.hours_description"
        label="Example: 9am - 5pm"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
      />
    </v-col>
    <v-col
      cols="12"
      class="align-baseline d-flex"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label"
        >Capacity*
        <tip-icon>
          Enter the number of volunteer spots available.
        </tip-icon>
      </label>
      <v-text-field
        v-model="need.capacity"
        label="Example: 30"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        required
        dense
        type="number"
        autocomplete="off"
        @input="onCapacityInput"
      />
    </v-col>
  <v-col
    cols="12"
    class="align-baseline d-flex"
    :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
  >
    <label class="title-label"
      >Start Date*
      <tip-icon>
        Select the date when the need is scheduled to start.
      </tip-icon>
    </label>
    <v-menu
      v-model="startDateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedStartDate"
          label="Select Start Date"
          readonly
          solo
          flat
          outlined
          dense
          v-bind="attrs"
          v-on="on"
           :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        />
      </template>
      <v-date-picker
        v-model="need.start_date"
        :min="new Date().toISOString().substr(0, 10)"
        @input="startDateMenu = false"
      ></v-date-picker>
    </v-menu>
  </v-col>

  <v-col
    cols="12"
    class="align-baseline d-flex"
    :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
  >
    <label class="title-label"
      >End Date*
      <tip-icon>
        Select the date when the need is scheduled to end.
      </tip-icon>
    </label>
    <v-menu
      v-model="endDateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedEndDate"
          label="Select End Date"
          readonly
          solo
          flat
          outlined
          dense
          v-bind="attrs"
          v-on="on"
           :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        />
      </template>
      <v-date-picker
        v-model="need.end_date"
        :min="new Date().toISOString().substr(0, 10)"
        @input="endDateMenu = false"
      ></v-date-picker>
    </v-menu>
  </v-col>
  </v-row>
</template>
<script>
import TipIcon from "@/components/common/TipIcon.vue";
export default {
  components: { TipIcon },
  props: {
    need: Object,
    hoursMessage: String,
    onChange: Function
  },
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
    };
  },
  computed: {
    formattedStartDate() {
      if (!this.need.start_date) return '';
      const date = new Date(this.need.start_date);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    },
    formattedEndDate() {
      if (!this.need.end_date) return '';
      const date = new Date(this.need.end_date);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
  },
  methods: {
    onCapacityInput(value) {
      const numericValue = value.replace(/[^0-9]/g, '');
      if (numericValue === '' || Number(numericValue) < 1) {
        this.need.capacity = 1;
      } else {
        this.need.capacity = Number(numericValue);
      }
    }
  }
};
</script>
<style scoped>
.title-label {
  width: 200px;
}
.warn-title{
color: red;
    font-size: 12px;
    position: relative;
    bottom: 66px;
    padding: 0px 0px 0px 28%;
    top: -35px;
}
</style>
