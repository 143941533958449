<template>
  <v-row>
    <!-- <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label"
        >Need Date*
        <tip-icon>
          Select the date of the volunteer opportunity. If this is a "Runs
          until" need. Select the final date of the need.
        </tip-icon>
      </label>
      <v-menu
        v-model="date_picker"
        :close-on-content-click="false"
        :return-value.sync="endDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
        :top="$vuetify.breakpoint.xs"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="need.end_date"
            label="MM/DD/YYYY"
            :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
            solo
            flat
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            autocomplete="off"
          />
        </template>
        <v-date-picker v-model="endDate" no-title scrollable :min="new Date().toISOString().substr(0, 10)">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="date_picker = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="onSaveEndDate(endDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col> -->
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label"
        >Registration Closed Date*
        <tip-icon>
          Registration will close at midnight on this date. Volunteers can
          register before this date, but not on it.
        </tip-icon>
      </label>
      <v-menu
        v-model="date_picker_closed_date"
        :close-on-content-click="false"
        :return-value.sync="closedDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
        :top="$vuetify.breakpoint.xs"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="need.registration_closed_date"
            label="MM/DD/YYYY"
            :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
            solo
            flat
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            autocomplete="off"
          />
        </template>
        <v-date-picker v-model="closedDate" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="date_picker_closed_date = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="onSaveClosedDate(closedDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label"
        >Capacity*
        <tip-icon>
          Enter the number of volunteer spots available.
        </tip-icon>
      </label>
      <v-text-field
        v-model="need.capacity"
        label="30"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
        :min="1"
        type="number"
        @input="onCapacityInput"
      />
    </v-col>
     <v-col
    cols="12"
    class="align-baseline d-flex"
    :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
  >
    <label class="title-label"
      >Start Date*
      <tip-icon>
        Select the date when the need is scheduled to start.
      </tip-icon>
    </label>
    <v-menu
      v-model="startDateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedStartDate"
          label="Select Start Date"
          readonly
          solo
          flat
          outlined
          dense
          v-bind="attrs"
          v-on="on"
           :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        />
      </template>
      <v-date-picker
        v-model="need.start_date"
        :min="new Date().toISOString().substr(0, 10)"
        @input="startDateMenu = false"
      ></v-date-picker>
    </v-menu>
  </v-col>

  <v-col
    cols="12"
    class="align-baseline d-flex"
    :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
  >
    <label class="title-label"
      >End Date*
      <tip-icon>
        Select the date when the need is scheduled to end.
      </tip-icon>
    </label>
    <v-menu
      v-model="endDateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedEndDate"
          label="Select End Date"
          readonly
          solo
          flat
          outlined
          dense
          v-bind="attrs"
          v-on="on"
           :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        />
      </template>
      <v-date-picker
        v-model="need.end_date"
        :min="new Date().toISOString().substr(0, 10)"
        @input="endDateMenu = false"
      ></v-date-picker>
    </v-menu>
  </v-col>
    <v-col
      cols="12"
      class="d-flex flex-row"
      style="align-items: baseline"
      ref="hours"
    >
      <label class="title-label"
        >Hours*
        <tip-icon>
          Enter the number of hours the volunteer is expected to work.<br />
          This number is used by your volunteer check-in feature.
          <br />For multi-date needs, Enter the average number of hours expected
          each day.
        </tip-icon>
      </label>
      <v-text-field
        v-model="need.hours"
        label="Example: 4 or 3.5"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
         :min="1"
        type="number"
        :error-messages="hoursMessage"
        @input="onChange"
      />
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label"
        >Hours Description*
        <tip-icon>
          Let the volunteer know when the need is scheduled to start and end.
        </tip-icon>
      </label>
      <v-text-field
        v-model="need.hours_description"
        label="Example: 9am - 5pm"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
      />
    </v-col>
  </v-row>
</template>
<script>
import TipIcon from "@/components/common/TipIcon.vue";
export default {
  components: { TipIcon },
  props: {
    need: Object,
    hoursMessage: String,
    onChange: Function
  },
  data() {
    return {
      date_picker: false,
      endDate: null,
      date_picker_closed_date: false,
      closedDate: null
    };
  },
  computed:{
        formattedStartDate() {
      if (!this.need.start_date) return '';
      const date = new Date(this.need.start_date);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    },
    formattedEndDate() {
      if (!this.need.end_date) return '';
      const date = new Date(this.need.end_date);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
  },
  methods: {
    onCapacityInput(value) {
      const numericValue = value.replace(/[^0-9]/g, '');
      if (numericValue === '' || Number(numericValue) < 1) {
        this.need.capacity = 1;
      } else {
        this.need.capacity = Number(numericValue);
      }
    },
    changeDateFormat(dateStr) {
      return `${dateStr.substring(5, 7)}/${dateStr.substring(
        8,
        10
      )}/${dateStr.substring(0, 4)}`;
    },
    onSaveEndDate(dateStr) {
      this.need.end_date = this.changeDateFormat(dateStr);
      this.date_picker = false;
    },
    onSaveClosedDate(dateStr) {
      this.need.registration_closed_date = this.changeDateFormat(dateStr);
      this.date_picker_closed_date = false;
    }
  }
};
</script>
<style scoped>
.title-label {
  width: 200px;
}
</style>
